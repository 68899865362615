<template>
  <div class="content-header">
    <div class="d-flex align-items-center">
      <div class="me-auto">
        <h3 class="page-title">Settings</h3>
        <div class="d-inline-block align-items-center">
          <nav>
            <ol class="breadcrumb">
              <li class="breadcrumb-item"><a href="#"><i class="mdi mdi-home-outline"></i></a></li>
              <li class="breadcrumb-item" aria-current="page">Role</li>
              <li class="breadcrumb-item active" aria-current="page">Add</li>
            </ol>
          </nav>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-12">
      <div class="box">
        <div class="box-header with-border">
          <h4 class="box-title">Role Information</h4>
        </div>
        <!-- /.box-header -->
        <form class="form">
          <div class="box-body">
            <h4 class="box-title text-info mb-0"><i class="fas fa-info me-15"></i> Basic Information</h4>
            <hr class="my-15">
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label class="form-label">Name (Thai)</label>
                  <input type="text" class="form-control">
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label class="form-label">Name (English)</label>
                  <input type="text" class="form-control">
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <div class="checkbox checkbox-success">
                    <input id="checkbox1"
                           v-model="form.is_active"
                           type="checkbox">
                    <label for="checkbox1"> Active </label>
                  </div>
                </div>
              </div>
            </div>
            <h4 class="box-title text-info mb-0 mt-20"><i class="fas fa-lock me-15"></i> Permission</h4>
            <hr class="my-15">
            <div class="row">
              <div class="col-md-12">
                <table class="table table-hover">
                  <thead>
                  <tr>
                    <th></th>
                    <th>Position</th>
                    <th>Menu Name</th>
                    <th>Description</th>
                  </tr>
                  </thead>

                  <tbody>
                  <tr v-for="(privilege, i) in privileges"
                      :class="{'table-active': !privilege.menu_route}"
                      :key="'privilege_'+i">
                    <td>
                      <div class="form-group" v-if="privilege.menu_route">
                        <div class="checkbox checkbox-success">
                          <input id="checkbox1" type="checkbox">
                          <label for="checkbox1">&nbsp;</label>
                        </div>
                      </div>
                    </td>

                    <td>{{ privilege.menu_position }}</td>
                    <td>{{ privilege.menu_name_th }}</td>
                    <td></td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <!-- /.box-body -->
          <div class="box-footer">
            <router-link :to="{name: 'settings_role'}" class="btn btn-warning me-1">
              <i class="ti-trash"></i> Cancel
            </router-link>
            <button type="submit" class="btn btn-primary">
              <i class="ti-save-alt"></i> Save
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      privileges: [],
      form: {
        id: '',
        is_active: true,
        menu_list: [
          {
            is_check: true,
            menu_code: true,
            menu_name_th: '',
            menu_ref_code: ''
          }
        ],
        name_en: '',
        name_th: ''
      }
    }
  },
  created() {
    this.loadPrivileges();
  },
  methods: {
    loadPrivileges() {
      this.axios.post('/userrolemenu/privilege')
        .then(response => {
          if (response.data.status) {
            this.privileges = response.data.results.list;
          }
        })
    }
  }
}
</script>