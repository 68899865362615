<template>
  <div class="content-header">
    <div class="d-flex align-items-center">
      <div class="me-auto">
        <h3 class="page-title">{{ $t('setting.settings') }}</h3>
        <div class="d-inline-block align-items-center">
          <nav>
            <ol class="breadcrumb">
              <li class="breadcrumb-item"><a href="#"><i class="mdi mdi-home-outline"></i></a></li>
              <li class="breadcrumb-item" aria-current="page">{{ $t('setting.user') }}</li>
              <li class="breadcrumb-item active" aria-current="page">{{ title }}</li>
            </ol>
          </nav>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-12">
      <div class="box">
        <div class="box-header with-border small-box-header">
          <h4 class="box-title">ข้อมูลผู้ใช้งาน</h4>
        </div>
        <!-- /.box-header -->
        <div class="box-body p-3">
          <div class="row">
            <div class="col-md-2">
              <img onerror="this.src='/images/avatar-girl.png'"
                   :src="form.image ? form.image : '/images/avatar-girl.png'" >

              <div class="d-grid">
                <button class="mt-2 btn btn-primary btn-outline btn-sm"
                        @click.prevent="chooseImage">
                  <i class="fas fa-image"></i> อัปโหลดรูปโปรไฟล์
                </button>

                <input class="d-none"
                       type="file"
                       @change="readURL($event.target)"
                       ref="imageInput"
                       accept="image/*">
              </div>
            </div>
            <div class="col-md-10">
              <div class="form-group form-group-sm row mb-0">
                <label class="col-sm-2 col-form-label">สถานะใช้งาน</label>
                <div class="col-sm-9">
                  <div class="c-inputs-stacked">
                    <input name="user_type_status"
                           type="radio"
                           v-model="form.status"
                           id="user_type_active" value="1">
                    <label for="user_type_active" class="me-30">เปิด</label>
                    <input name="user_type_status"
                           type="radio"
                           v-model="form.status"
                           id="user_type_inactive" value="0">
                    <label for="user_type_inactive" class="me-30">ปิด</label>
                  </div>
                </div>
              </div>
              <div class="form-group form-group-sm row">
                <label class="col-sm-2 col-form-label">ประเภทผู้ใช้งาน</label>
                <div class="col-sm-10">
                  <select class="form-control form-control-sm select2"
                          id="role-select"
                          multiple="multiple"
                          style="width: 100%;">
                    <option :value="role.id"
                            v-for="role in roles"
                            :key="'role_'+role.id">{{ role.name_en }}</option>
                  </select>
                </div>
              </div>
              <div :class="['form-group form-group-sm row mb-0', {'error': errorFor('employee_id')}]">
                <label class="col-sm-2 col-form-label">รหัสพนักงาน <span class="text-danger">*</span></label>
                <div class="col-sm-4">
                  <input class="form-control form-control-sm"
                         v-model="form.employee_id"
                         type="text">

                  <v-errors :errors="errorFor('employee_id')"></v-errors>
                </div>
              </div>
              <div :class="['form-group form-group-sm row mb-0', {'error': errorFor('name_prefix_id')}]">
                <label class="col-sm-2 col-form-label">คำนำหน้าชื่อ <span class="text-danger">*</span></label>
                <div class="col-sm-4">
                  <select class="form-select form-select-sm"
                          v-model="form.name_prefix_id">
                    <option value="">Select Prefix</option>
                    <option :value="prefix.id"
                            v-for="prefix in prefixes"
                            :key="'prefix_'+prefix.id">{{ prefix.prefix }}</option>
                  </select>

                  <v-errors :errors="errorFor('name_prefix_id')"></v-errors>
                </div>
              </div>
              <div :class="['form-group form-group-sm row mb-0', {'error': errorFor('first_name') || errorFor('last_name')}]">
                <label class="col-sm-2 col-form-label">ชื่อ - นามสกุล <span class="text-danger">*</span></label>
                <div class="col-sm-5">
                  <input class="form-control form-control-sm"
                         v-model="form.first_name"
                         placeholder="ชื่อ"
                         type="text">
                  <v-errors :errors="errorFor('first_name')"></v-errors>
                </div>
                <div class="col-sm-5">
                  <input class="form-control form-control-sm"
                         placeholder="นามสกุล"
                         v-model="form.last_name"
                         type="text">
                  <v-errors :errors="errorFor('last_name')"></v-errors>
                </div>
              </div>
              <div :class="['form-group form-group-sm row mb-0', {'error': errorFor('nick_name')}]">
                <label class="col-sm-2 col-form-label">ชื่อเล่น</label>
                <div class="col-sm-5">
                  <input class="form-control form-control-sm"
                         v-model="form.nick_name"
                         type="text">
                  <v-errors :errors="errorFor('nick_name')"></v-errors>
                </div>
              </div>
              <div :class="['form-group form-group-sm row mb-0', {'error': errorFor('phone')}]">
                <label class="col-sm-2 col-form-label">เบอร์โทรศัพท์ <span class="text-danger">*</span></label>
                <div class="col-sm-4">
                  <input class="form-control form-control-sm"
                         v-model="form.phone"
                         type="text">
                  <v-errors :errors="errorFor('phone')"></v-errors>
                </div>
              </div>
              <div :class="['form-group form-group-sm row mb-0', {'error': errorFor('email')}]">
                <label class="col-sm-2 col-form-label">อีเมล <span class="text-danger">*</span></label>
                <div class="col-sm-5">
                  <input class="form-control form-control-sm"
                         v-model="form.email"
                         type="email">
                  <v-errors :errors="errorFor('email')"></v-errors>
                </div>
              </div>
              <div :class="['form-group form-group-sm row mb-0', {'error': errorFor('password')}]">
                <label class="col-sm-2 col-form-label">รหัสผ่าน <span class="text-danger">*</span></label>
                <div class="col-sm-5">
                  <input class="form-control form-control-sm"
                         v-model="form.password"
                         type="password">
                  <v-errors :errors="errorFor('password')"></v-errors>
                </div>
              </div>
              <div :class="['form-group form-group-sm row mb-0', {'error': errorFor('password_confirmation')}]">
                <label class="col-sm-2 col-form-label">ยืนยันรหัสผ่าน <span class="text-danger">*</span></label>
                <div class="col-sm-5">
                  <input class="form-control form-control-sm"
                         v-model="form.password_confirmation"
                         type="password">
                  <v-errors :errors="errorFor('password_confirmation')"></v-errors>
                </div>
              </div>
              <div :class="['form-group form-group-sm row mb-0', {'error': errorFor('departments')}]">
                <label class="col-sm-2 col-form-label">แผนกบริการ</label>
                <div class="col-sm-10">
                  <select class="form-control form-control-sm select2"
                          multiple="multiple"
                          id="department-select"
                          style="width: 100%;">
                    <option :value="department.id"
                            v-for="department in departments"
                            :key="'department_'+department.id">{{ department.name }}</option>
                  </select>
                  <v-errors :errors="errorFor('departments')"></v-errors>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- /.box-body -->
        <div class="box-footer text-center">
          <router-link :to="{name: 'settings_user'}" class="btn btn-secondary me-1">
            {{ $route.name === 'settings_user_edit' ? 'ยกเลิกการแก้ไข' : 'ยกเลิกการสร้าง' }}
          </router-link>
          <button type="submit"
                  @click="submit"
                  class="btn btn-primary">
            บันทึกข้อมูล
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      title: 'Add',
      roles: [],
      departments: [],
      prefixes: [],
      form: {
        image: '',
        status: '1',
        roles: [],
        departments: [],
        employee_id: '',
        name_prefix_id: '',
        nick_name: '',
        first_name: '',
        last_name: '',
        phone: '',
        email: '',
        password: '',
        password_confirmation: '',
      }
    }
  },
  mounted() {
    const loadDepartments = this.loadDepartments();
    const loadRoles = this.loadRoles();
    const loadPrefixes = this.loadPrefixes();

    let reqs = [loadDepartments, loadRoles, loadPrefixes];

    if (this.$route.name === 'settings_user_edit') {
      reqs.push(this.loadUser());
      this.title = 'Edit';
    }

    Promise.all(reqs)
      .then(() => {
        this.initSelect2();
      })
  },
  methods: {
    chooseImage() {
      this.$refs.imageInput.click();
    },
    readURL(input) {
      let self = this;

      if (input.files && input.files[0]) {
        let reader = new FileReader();

        reader.onload = function (e) {
          self.form.image = e.target.result;
        }

        reader.readAsDataURL(input.files[0]);
      }
    },
    initSelect2() {
      let self = this;

      window.$('.select2').select2();

      window.$('#department-select').val(this.form.departments).trigger('change');
      window.$('#role-select').val(this.form.roles).trigger('change');

      window.$('#role-select').on('change', function () {
        self.form.roles = window.$("#role-select").select2("val");
      });

      window.$('#department-select').on('change', function () {
        self.form.departments = window.$("#department-select").select2("val");
      });
    },
    loadUser() {
      return new Promise((resolve, reject) => {
        this.axios.get('/users/' + this.$route.params.id)
          .then(res => {
            let user = res.data.data;
            this.form = user;
            this.form.name_prefix_id = user.namePrefix.id;
            this.form.departments = user.departments.map(d => d.id);
            this.form.roles = user.roles.map(r => r.id);

            delete this.form.id
            delete this.form.namePrefix
            resolve(res)
          }).catch((err) => {
          reject(err)
        })
      });
    },
    loadDepartments() {
      return new Promise((resolve, reject) => {
        this.axios.get('/departments')
          .then(res => {
            this.departments = res.data.data;
            resolve(res)
          }).catch((err) => {
          reject(err)
        })
      });
    },
    loadPrefixes() {
      return new Promise((resolve, reject) => {
        this.axios.get('/name-prefixes')
          .then(res => {
            this.prefixes = res.data.data;
            resolve(res)
          }).catch((err) => {
          reject(err)
        })
      });
    },
    loadRoles() {
      return new Promise((resolve, reject) => {
        this.axios.get('/roles?status=1')
          .then(res => {
            this.roles = res.data.data;
            resolve(res)
          }).catch((err) => {
          reject(err)
        })
      });
    },
    submit() {
      this.allErrors = null;
      this.loading = true;

      let url = '/users';

      if (this.$route.name === 'settings_user_edit') {
        url = '/users/' + this.$route.params.id;
        this.form._method = 'PATCH'
      } else {
        delete this.form._method;
      }

      this.axios.post(url, this.form)
        .then(() => {
          this.$router.push({name: 'settings_user'})
        })
        .catch((err) => {
          this.allErrors = err.response.data.errors;
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
}
</script>