<template>
  <div class="content-header">
    <div class="d-flex align-items-center">
      <div class="me-auto">
        <h3 class="page-title">{{ $t('setting.settings') }}</h3>
        <div class="d-inline-block align-items-center">
          <nav>
            <ol class="breadcrumb">
              <li class="breadcrumb-item"><a href="#"><i class="mdi mdi-home-outline"></i></a></li>
              <li class="breadcrumb-item active" aria-current="page">{{ $t('setting.user') }}</li>
            </ol>
          </nav>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-md-12">
      <button class="btn btn-sm btn-primary me-3">ผู้ใช้งาน</button>
      <router-link :to="{name: 'settings_role'}"
                   class="btn btn-sm btn-secondary">ประเภทผู้ใช้งาน</router-link>
    </div>
  </div>

  <div class="row mt-3">
    <div class="col-12">
      <div class="box">
        <div class="box-header small-box-header">
          <div class="row">
            <div class="col-md-9">
              <div class="input-group">
                <input type="text"
                       @keyup="searchText($event.target.value)"
                       placeholder="ค้นหาด้วยการระบุ ชื่อประเภท (TH) หรือ (EN)"
                       class="form-control form-control-sm">
                <span class="input-group-text"><i class="fa fa-search"></i></span>
              </div>
            </div>
            <div class="col-md-2">
              <select class="form-select form-select-sm"
                      @change="refreshData"
                      v-model="queryParams.status">
                <option value="">{{ $t('setting.all_status') }}</option>
                <option value="1">Active</option>
                <option value="0">Inactive</option>
              </select>
            </div>
            <div class="col-md-1">
              <div class="d-grid">
                <router-link :to="{name: 'settings_user_add'}"
                             class="btn btn-primary btn-sm">+ {{ $t('appointments.add_new') }}</router-link>
              </div>
            </div>
          </div>
        </div>
        <div class="box-body p-3">
          <table-component :columns="columns"
                           :rows="users"
                           @sorting="sorting"
                           :pagination="pagination"
                           @search="search"
                           :search="false"
                           @pageChanged="pageChanged">

            <template v-slot:action="{ row }">
              <router-link :to="{name: 'settings_user_edit', params: {id: row.id}}" class="text-muted">
                <i class="fas fa-edit"></i> {{ $t('setting.edit') }}
              </router-link> |
              <a href="#"
                 @click.prevent="deleteData(row)"
                 class="text-muted">
                <i class="fas fa-trash"></i> {{ $t('setting.delete') }}
              </a>
            </template>
          </table-component>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TableComponent from "@/components/TableComponent";
export default {
  components: {TableComponent},
  data() {
    return {
      users: [],
      pagination: null,
      queryParams: {
        status: '',
        page: 1,
        search: '',
        sort: 'employee_id',
        sort_order: 'asc',
        per_page: 10
      },
      sortable: {
        order: 'employee_id',
        sort: 'asc'
      },
      columns: [
        {
          label: "User ID",
          field: "employee_id",
          sortable: true,
          searchable: true,
        },
        {
          label: "Name",
          field: 'full_name',
          sortable: true,
          searchable: true,
        },
        {
          label: "Phone",
          field: 'phone',
          sortable: true,
          searchable: true,
        },
        {
          label: "Email",
          field: 'email',
          sortable: true,
          searchable: true,
        },
        {
          label: "Department",
          field: 'department',
          sortable: false,
          searchable: false,
          display(row) {
            return row.departments.map(d => d.name).join(', ');
          }
        },
        {
          label: "Status",
          field: 'status',
          sortable: true,
          searchable: false,
          display(row) {
            if (row.status)
              return '<span class="badge badge-success-light">เปิดใช้งาน</span>'
            else
              return '<span class="badge badge-secondary-light">ปิดใช้งาน</span>';
          }
        },
        {
          label: "Updated At",
          field: 'updated_at',
          sortable: true,
          searchable: false,
        },
        {
          label: "Updated By",
          field: 'updatedBy.full_name',
          sortable: false,
          searchable: false,
        },
        {
          label: "Action",
          field: 'action',
          sortable: false,
          slot: true,
          class: ['text-end']
        }
      ],
    }
  },
  created() {
    this.loadData()
  },
  methods: {
    loadData() {
      this.users = [];

      this.axios.get('/users', {params: this.queryParams})
        .then((response) => {
          this.users = response.data.data;
          this.pagination = response.data.meta;
        })
    },
    deleteData(row) {
      this.$swal({
        title: this.$t('patients.are_you_sure_want_to_delete'),
        text: this.$t('patients.you_wont_be_able_to_revert_this'),
        icon: 'warning',
        showCancelButton: true,
        cancelButtonText: this.$t('patients.cancel'),
        confirmButtonText: this.$t('patients.yes_delete_it'),
        confirmButtonColor: '#dd4b39',
        reverseButtons: true
      }).then((result) => {
        if (result.isConfirmed) {
          this.axios.delete('/users/'+row.id).then(() => {
            this.loadData();
            this.$swal.fire(
              this.$t('patients.deleted'),
              'User has been deleted!',
              'success'
            )
          });
        }
      });
    },
  }
}
</script>