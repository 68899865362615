<template>
  <div class="row">
    <div class="col-12">
      <div class="box">
        <div class="box-header small-box-header">
          <div class="row">
            <div class="col-md-9">
              <div class="input-group">
                <input type="text"
                       @keyup="searchText($event.target.value)"
                       placeholder="ค้นหาด้วยการระบุ รหัสบริการ, ชื่อบริการ (ไทย) หรือ ชื่อบริการ (อังกฤษ)"
                       class="form-control form-control-sm">
                <span class="input-group-text"><i class="fa fa-search"></i></span>
              </div>
            </div>
            <div class="col-md-2">
              <select class="form-select form-select-sm"
                      @change="refreshData"
                      v-model="queryParams.status">
                <option value="">{{ $t('setting.all_status') }}</option>
                <option value="1">Active</option>
                <option value="0">Inactive</option>
              </select>
            </div>
            <div class="col-md-1">
              <div class="d-grid">
                <router-link :to="{name: 'settings_service_add'}"
                             class="btn btn-primary btn-sm">+ {{ $t('appointments.add_new') }}</router-link>
              </div>
            </div>
          </div>
        </div>
        <div class="box-body p-3">
          <table-component :columns="columns"
                           :rows="services"
                           :search="false"
                           :pagination="pagination"
                           @pageChanged="pageChanged"
                           :sortable="sortable"
                           @sorting="sorting"
                           @search="search">
            <template v-slot:action="{ row }">
              <router-link :to="{name: 'settings_service_edit', params: {id: row.id}}"
                           class="text-muted"><i class="fas fa-pen"></i>
              </router-link> |

              <a href="#" class="text-muted"
                 @click.prevent="deleteData(row)"><i class="fas fa-trash"></i></a>
            </template>
          </table-component>
        </div>
      </div>
    </div>
  </div>

  <details-view-modal ref="detailsViewModal" />
  <add-new-modal ref="addNewModal" @added="loadData" />
</template>

<script>
import DetailsViewModal from "@/views/setting/product/modals/DetailsViewModal";
import AddNewModal from "@/views/setting/product/modals/AddNewModal";
import TableComponent from "@/components/TableComponent";

export default {
  components: {DetailsViewModal, AddNewModal, TableComponent},
  data() {
    return {
      services: [],
      categories: [],
      groupCategories: [],
      pagination: null,
      queryParams: {
        status: '',
        page: 1,
        search: '',
        sort: 'code',
        sort_order: 'asc',
        per_page: 10
      },
      sortable: {
        order: 'code',
        sort: 'asc'
      },
      columns: [
        {
          label: "รหัสบริการ",
          field: "code",
          sortable: true,
          searchable: true,
        },
        {
          label: "ชื่อบริการ (ไทย)",
          field: 'name_th',
          sortable: true,
          searchable: true,
        },
        {
          label: "ชื่อบริการ (อังกฤษ)",
          field: 'name_en',
          sortable: true,
          searchable: true,
        },
        {
          label: "เวลา/ครั้ง",
          field: 'time',
          sortable: true,
          searchable: true,
          display(row) {
            return row.time + ' นาที'
          }
        },
        {
          label: "ราคา (บาท)",
          field: 'price',
          sortable: true,
          searchable: true,
          display(row) {
            return row.time + '/ครั้ง'
          }
        },
        {
          label: "สถานะ",
          field: 'status',
          sortable: true,
          searchable: false,
          display(row) {
            if (row.status)
              return '<span class="badge badge-success-light">เปิดใช้งาน</span>'
            else
              return '<span class="badge badge-secondary-light">ปิดใช้งาน</span>';
          }
        },
        {
          label: "วันที่ปรับปรุง",
          field: 'updated_at',
          sortable: true,
          searchable: false,
        },
        {
          label: "ผู้ปรับปรุง",
          field: 'updatedBy.full_name',
          sortable: false,
          searchable: false,
        },
        {
          label: "Action",
          field: 'action',
          sortable: false,
          slot: true,
          class: ['text-end']
        }
      ],
    }
  },
  created() {
    this.loadData();
  },
  methods: {
    loadData() {
      this.services = [];

      this.axios.get('/services', {params: this.queryParams})
        .then((response) => {
          this.services = response.data.data;
          this.pagination = response.data.meta;
        })
    },
    deleteData(row) {
      this.$swal({
        title: 'Are you sure want to delete?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        confirmButtonColor: '#dd4b39',
        reverseButtons: true
      }).then((result) => {
        if (result.isConfirmed) {
          this.axios.delete('/services/'+row.id).then(() => {
            this.loadData();
            this.$swal.fire(
              'Deleted',
              'Service has been deleted!',
              'success'
            )
          });
        }
      });
    },
  }
}
</script>