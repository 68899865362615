<template>
  <div class="row">
    <div class="col-12">
      <div class="box">
        <div class="box-header small-box-header">
          <div class="row">
            <div class="col-md-4">
              <div class="input-group">
                <input type="text"
                       @keyup="searchText($event.target.value)"
                       placeholder="ค้นหาด้วยการระบุ รหัสสินค้า หรือ ชื่อสินค้า"
                       class="form-control form-control-sm">
                <span class="input-group-text"><i class="fa fa-search"></i></span>
              </div>
            </div>
            <div class="col-md-2">
              <select class="form-select form-select-sm"
                      @change="changeCategory"
                      v-model="queryParams.product_category_id">
                <option value="">หมวด: ทุกหมวด</option>
                <option :value="category.id"
                        v-for="category in categories"
                        :key="'category_'+category.id">{{ category.name_th }}</option>
              </select>
            </div>
            <div class="col-md-2">
              <select class="form-select form-select-sm"
                      @change="filter('product_group_category_id', $event.target.value)"
                      v-model="queryParams.product_group_category_id">
                <option value="">หมู่: ทุกหมู่</option>
                <option :value="group.id"
                        v-for="group in groupCategories"
                        :key="'group_'+group.id">{{ group.name_th }}</option>
              </select>
            </div>
            <div class="col-md-2">
              <select class="form-select form-select-sm"
                      @change="refreshData"
                      v-model="queryParams.status">
                <option value="">{{ $t('setting.all_status') }}</option>
                <option value="1">{{  $t('setting.active') }}</option>
                <option value="0">{{ $t('setting.inactive') }}</option>
              </select>
            </div>
            <div class="col-md-1">
              <div class="d-grid">
                <div class="btn-group">
                  <button type="button"
                          class="waves-effect waves-light btn btn-primary btn-sm dropdown-toggle"
                          data-bs-toggle="dropdown" aria-expanded="false">{{ $t('setting.import') }}</button>
                  <div class="dropdown-menu dropdown-menu-lg-end">
                    <a class="dropdown-item"
                       @click.prevent="$refs.excelInput.click()"
                       href="#">
                      <i class="fas fa-upload"></i> {{ $t('upload_file') }}
                    </a>
                    <div class="dropdown-divider"></div>
                    <a class="dropdown-item"
                       href="/Sample.xlsx" download>
                      <i class="fas fa-download"></i> {{ $t('download_sample_file') }}
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-1">
              <div class="d-grid">
                <button @click="$refs.addNewModal.show()"
                        class="btn btn-primary btn-sm">+ Add New</button>
              </div>
            </div>
          </div>
        </div>
        <div class="box-body p-3">
          <table-component :columns="columns"
                           :rows="products"
                           :search="false"
                           :pagination="pagination"
                           @pageChanged="pageChanged"
                           :sortable="sortable"
                           @sorting="sorting"
                           @search="search">
            <template v-slot:action="{ row }">
              <a href="#" class="text-muted"
                 @click.prevent="$refs.detailsViewModal.show(row)"><i class="fas fa-eye"></i>
              </a> |

              <a href="#" class="text-muted"
                 @click.prevent="edit(row)"><i class="fas fa-pen"></i>
              </a> |

              <a href="#" class="text-muted"
                 @click.prevent="deleteData(row)"><i class="fas fa-trash"></i></a>
            </template>
          </table-component>
        </div>
      </div>
    </div>
  </div>

  <input class="d-none"
         type="file"
         @change="readURL($event.target)"
         ref="excelInput"
         accept=".xlsx, .xls, .csv">

  <details-view-modal ref="detailsViewModal" />
  <add-new-modal ref="addNewModal" @added="loadData" />
</template>

<script>
import DetailsViewModal from "@/views/setting/product/modals/DetailsViewModal";
import AddNewModal from "@/views/setting/product/modals/AddNewModal";
import TableComponent from "@/components/TableComponent";

export default {
  components: {DetailsViewModal, AddNewModal, TableComponent},
  data() {
    return {
      products: [],
      categories: [],
      groupCategories: [],
      pagination: null,
      queryParams: {
        status: '',
        product_category_id: '',
        product_group_category_id: '',
        page: 1,
        search: '',
        sort: 'code',
        sort_order: 'asc',
        per_page: 10
      },
      sortable: {
        order: 'code',
        sort: 'asc'
      },
      columns: [
        {
          label: "รหัสสินค้า",
          field: "code",
          sortable: true,
          searchable: true,
        },
        {
          label: "ชื่อสินค้า (ขาย)",
          field: 'name_sale',
          sortable: true,
          searchable: true,
        },
        {
          label: "หมวด",
          field: 'productCategory.name_th',
          sortable: true,
          searchable: true,
        },
        {
          label: "หมู่",
          field: 'productGroupCategory.name_th',
          sortable: true,
          searchable: true,
        },
        {
          label: "ยอดคงเหลือ",
          field: 'balance',
          sortable: true,
          searchable: true,
        },
        {
          label: "หน่วยนับ",
          field: 'productUnit.name_th',
          sortable: true,
          searchable: true,
        },
        {
          label: "สถานะ",
          field: 'status',
          sortable: true,
          searchable: false,
          display(row) {
            if (row.status)
              return '<span class="badge badge-success-light">เปิดใช้งาน</span>'
            else
              return '<span class="badge badge-secondary-light">ปิดใช้งาน</span>';
          }
        },
        {
          label: "วันที่ปรับปรุง",
          field: 'updated_at',
          sortable: true,
          searchable: false,
        },
        {
          label: "ผู้ปรับปรุง",
          field: 'updatedBy.full_name',
          sortable: false,
          searchable: false,
        },
        {
          label: "Action",
          field: 'action',
          sortable: false,
          slot: true,
          class: ['text-end']
        }
      ],
    }
  },
  created() {
    this.loadData();
    this.loadCategories();
  },
  methods: {
    loadCategories() {
      this.categories = [];

      this.axios.get('/product-categories?status=1')
        .then(res => {
          this.categories = res.data.data;
        })
    },
    changeCategory() {
      this.filter('product_category_id', this.queryParams.product_category_id);
      this.loadGroupCategories();
    },
    loadGroupCategories() {
      this.groupCategories = [];
      this.queryParams.product_group_category_id = '';

      if (this.queryParams.product_category_id) {
        this.axios.get('/product-group-categories?status=1&categoryId=' + this.queryParams.product_category_id)
          .then(res => {
            this.groupCategories = res.data.data;
          })
      }
    },
    loadData() {
      this.products = [];

      this.axios.get('/products', {params: this.queryParams})
        .then((response) => {
          this.products = response.data.data;
          this.pagination = response.data.meta;
        })
    },
    edit(row) {
      this.axios.get('/products/' + row.id)
        .then(res => {
          let product = res.data.data;
          this.$refs.addNewModal.show(product);
        });
    },
    readURL(input) {
      // let self = this;

      if (input.files && input.files[0]) {

        const formData = new FormData();
        formData.append('file', input.files[0]);
        const headers = { 'Content-Type': 'multipart/form-data' };
        this.axios.post('/products/excel-upload', formData, { headers })
          .then(() => {
            this.showSuccessMsg('File Upload Successfully!')
          });

        /*let reader = new FileReader();

        reader.onload = function (e) {
          self.axios.post('/products/excel-upload', {
              file: e.target.result
            })
            .then(() => {
              self.showSuccessMsg('File Upload Successfully!')
            })
        }

        reader.readAsDataURL(input.files[0]);*/
      }
    },
    deleteData(row) {
      this.$swal({
        title: this.$t('patients.are_you_sure_want_to_delete'),
        text: this.$t('patients.you_wont_be_able_to_revert_this'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        confirmButtonColor: '#dd4b39',
        reverseButtons: true
      }).then((result) => {
        if (result.isConfirmed) {
          this.axios.delete('/products/'+row.id).then(() => {
            this.loadData();
            this.$swal.fire(
              'Deleted',
              'Product has been deleted!',
              'success'
            )
          });
        }
      });
    },
  }
}
</script>