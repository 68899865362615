<template>
  <div class="modal fade bs-example-modal-lg"
       id="add-new-modal"
       tabindex="-1"
       role="dialog"
       aria-labelledby="myLargeModalLabel"
       aria-hidden="true">
    <div class="modal-dialog modal-dialog-scrollable">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title" id="myLargeModalLabel">
            {{ title }}
          </h4>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div class="form-group form-group-sm row mb-0">
            <label class="col-sm-4 col-form-label">สถานะสินค้า</label>
            <div class="col-sm-8">
              <div class="c-inputs-stacked">
                <input name="product_status"
                       type="radio"
                       v-model="form.status"
                       id="product_active" value="1">
                <label for="product_active" class="me-30">เปิดใช้งาน</label>
                <input name="product_status"
                       type="radio"
                       v-model="form.status"
                       id="product_inactive" value="0">
                <label for="product_inactive" class="me-30">ปิดใช้งาน</label>
              </div>
            </div>
          </div>
          <div :class="['form-group form-group-sm row mb-0', {'error': errorFor('name_sale')}]">
            <label class="col-sm-4 col-form-label">ชื่อสินค้า (ขาย)</label>
            <div class="col-sm-8">
              <input class="form-control form-control-sm"
                     v-model="form.name_sale"
                     type="text">

              <v-errors :errors="errorFor('name_sale')"></v-errors>
            </div>
          </div>
          <div :class="['form-group form-group-sm row mb-0', {'error': errorFor('name_purchase')}]">
            <label class="col-sm-4 col-form-label">ชื่อสินค้า (ซื้อ)</label>
            <div class="col-sm-8">
              <input class="form-control form-control-sm"
                     v-model="form.name_purchase"
                     type="text">

              <v-errors :errors="errorFor('name_purchase')"></v-errors>
            </div>
          </div>
          <div :class="['form-group form-group-sm row mb-0', {'error': errorFor('code')}]">
            <label class="col-sm-4 col-form-label">รหัสสินค้า</label>
            <div class="col-sm-8">
              <input class="form-control form-control-sm"
                     v-model="form.code"
                     type="text">

              <v-errors :errors="errorFor('code')"></v-errors>
            </div>
          </div>
          <div :class="['form-group form-group-sm row mb-0', {'error': errorFor('barcode')}]">
            <label class="col-sm-4 col-form-label">Barcode สินค้า</label>
            <div class="col-sm-8">
              <input class="form-control form-control-sm"
                     v-model="form.barcode"
                     type="text">

              <v-errors :errors="errorFor('barcode')"></v-errors>
            </div>
          </div>
          <div :class="['form-group form-group-sm row mb-0', {'error': errorFor('product_category_id')}]">
            <label class="col-sm-4 col-form-label">หมวดสินค้า</label>
            <div class="col-sm-8">
              <select class="form-select form-select-sm"
                      @change="loadGroupCategories"
                      v-model="form.product_category_id">
                <option value="">เลือก</option>
                <option :value="category.id"
                        v-for="category in categories"
                        :key="'category_'+category.id">{{ category.name_th }}</option>
              </select>

              <v-errors :errors="errorFor('product_category_id')"></v-errors>
            </div>
          </div>
          <div :class="['form-group form-group-sm row mb-0', {'error': errorFor('product_group_category_id')}]">
            <label class="col-sm-4 col-form-label">หมู่สินค้า</label>
            <div class="col-sm-8">
              <select class="form-select form-select-sm"
                      v-model="form.product_group_category_id">
                <option value="">เลือก</option>
                <option :value="group.id"
                        v-for="group in groupCategories"
                        :key="'group_'+group.id">{{ group.name_th }}</option>
              </select>

              <v-errors :errors="errorFor('product_group_category_id')"></v-errors>
            </div>
          </div>
          <div :class="['form-group form-group-sm row mb-0', {'error': errorFor('note')}]">
            <label class="col-sm-4 col-form-label">หมายเหตุ/ข้อบ่งใช้</label>
            <div class="col-sm-8">
              <input class="form-control form-control-sm"
                     v-model="form.note"
                     type="text">

              <v-errors :errors="errorFor('note')"></v-errors>
            </div>
          </div>

          <hr>

          <div class="form-group form-group-sm row mb-0">
            <label class="col-sm-4 col-form-label">ประเภทสินค้า</label>
            <div class="col-sm-8">
              <div class="c-inputs-stacked">
                <input name="product_type_status"
                       type="radio"
                       v-model="form.type"
                       id="product_type_active" value="sell">
                <label for="product_type_active" class="me-30">ขาย</label>
                <input name="product_type_status"
                       type="radio"
                       v-model="form.type"
                       id="product_type_inactive" value="not_sell">
                <label for="product_type_inactive" class="me-30">ไม่ขาย</label>
              </div>
            </div>
          </div>

          <div :class="['form-group form-group-sm row mb-0', {'error': errorFor('product_unit_id')}]">
            <label class="col-sm-4 col-form-label">หน่วยนับหลัก</label>
            <div class="col-sm-8">
              <select class="form-select form-select-sm"
                      v-model="form.product_unit_id">
                <option value="">เลือก</option>
                <option :value="unit.id"
                        v-for="unit in units"
                        :key="'unit_'+unit.id">{{ unit.name_th }}</option>
              </select>

              <v-errors :errors="errorFor('product_unit_id')"></v-errors>
            </div>
          </div>

          <div :class="['form-group form-group-sm row mb-0', {'error': errorFor('minimum_inventory')}]">
            <label class="col-sm-4 col-form-label">สินค้าคงคลังขั้นต่ำ</label>
            <div class="col-sm-8">
              <input class="form-control form-control-sm"
                     v-model="form.minimum_inventory"
                     type="text">

              <v-errors :errors="errorFor('minimum_inventory')"></v-errors>
            </div>
          </div>
          <div :class="['form-group form-group-sm row mb-0', {'error': errorFor('selling_price')}]">
            <label class="col-sm-4 col-form-label">ราคาขาย (บาท)</label>
            <div class="col-sm-8">
              <input class="form-control form-control-sm"
                     v-model="form.selling_price"
                     type="text">

              <v-errors :errors="errorFor('selling_price')"></v-errors>
            </div>
          </div>
          <div class="form-group form-group-sm row mb-0">
            <label class="col-sm-4 col-form-label">ประเภทหน่วยนับ</label>
            <div class="col-sm-8">
              <div class="box mb-1">
                <div class="box-header small-box-header">
                  <input name="unit_type"
                         v-model="form.unit_type"
                         type="radio"
                         id="unit_type_not_divided"
                         value="not_divided">
                  <label for="unit_type_not_divided" class="me-10">ไม่แบ่งหน่วยย่อย</label>
                </div>
              </div>
              <div class="box">
                <div class="box-header small-box-header">
                  <input name="unit_type"
                         v-model="form.unit_type"
                         type="radio"
                         id="unit_type_divided"
                         value="divided">
                  <label for="unit_type_divided" class="me-10">แบ่งหน่วยย่อย</label>
                </div>
                <div class="box-body p-2 bg-secondary-light" v-if="form.unit_type === 'divided'">
                  <div :class="['form-group form-group-sm row mb-0', {'error': errorFor('sub_product_unit_id')}]">
                    <label class="col-sm-6 col-form-label">หน่วยนับย่อย</label>
                    <div class="col-sm-6">
                      <select class="form-select form-select-sm"
                              v-model="form.sub_product_unit_id">
                        <option value="">เลือก</option>
                        <option :value="unit.id"
                                v-for="unit in units"
                                :key="'unit_'+unit.id">{{ unit.name_th }}</option>
                      </select>
                    </div>
                  </div>
                  <div :class="['form-group form-group-sm row mb-0', {'error': errorFor('sub_no_unit')}]">
                    <label class="col-sm-6 col-form-label">จำนวนหน่วยนับย่อย</label>
                    <div class="col-sm-6">
                      <input class="form-control form-control-sm"
                             v-model="form.sub_no_unit"
                             type="text">
                    </div>
                  </div>
                  <div :class="['form-group form-group-sm row mb-0', {'error': errorFor('sub_selling_price')}]">
                    <label class="col-sm-6 col-form-label">ราคาขาย (ย่อย)</label>
                    <div class="col-sm-6">
                      <input class="form-control form-control-sm"
                             v-model="form.sub_selling_price"
                             type="text">
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer text-center">
          <button type="button"
                  class="btn btn-secondary"
                  data-bs-dismiss="modal">ยกเลิก</button>

          <button class="btn btn-success"
                  @click="submit"
                  :disabled="loading">บันทึก</button>
        </div>
      </div>
      <!-- /.modal-content -->
    </div>
    <!-- /.modal-dialog -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      modal: null,
      title: 'เพิ่มสินค้า',
      product_id: '',
      categories: [],
      groupCategories: [],
      units: [],
      form: {
        name_sale: '',
        name_purchase: '',
        code: '',
        barcode: '',
        product_category_id: '',
        product_group_category_id: '',
        note: '',
        type: 'sell',
        product_unit_id: '',
        minimum_inventory: '',
        selling_price: '',
        unit_type: 'not_divided',
        sub_product_unit_id: '',
        sub_no_unit: '',
        sub_selling_price: '',
        status: '1'
      }
    }
  },
  created() {
    this.loadCategories();
    this.loadUnits();
  },
  mounted() {
    this.modal = new window.bootstrap.Modal(document.getElementById('add-new-modal'));
  },
  methods: {
    resetForm() {
      this.allErrors = null;

      Object.keys(this.form).forEach(key => {
        this.form[key] = '';
      });

      this.form.type = 'sell'
      this.form.unit_type = 'not_divided'
      this.form.status = '1'
    },
    show(product = null) {
      this.resetForm();

      if (product) {
        this.title = 'แก้ไขสินค้า';
        this.form = product;
        this.product_id = product.id;

        this.form.product_category_id = product.productCategory ? product.productCategory.id : '';
        this.form.product_group_category_id = product.productGroupCategory ? product.productGroupCategory.id : '';
        this.form.product_unit_id = product.productUnit ? product.productUnit.id : '';
        this.form.sub_product_unit_id = product.subProductUnit ? product.subProductUnit.id : '';

        if (product.productGroupCategory)
          this.loadGroupCategories(product.productGroupCategory.id);

        delete this.form.id;
        delete this.form.productCategory;
        delete this.form.productGroupCategory;
        delete this.form.productUnit;
        delete this.form.subProductUnit;
      } else {
        this.product_id = '';
        this.title = 'เพิ่มสินค้า';
      }

      this.modal.show();
    },
    loadUnits() {
      this.units = [];

      this.axios.get('/product-units?status=1')
        .then(res => {
          this.units = res.data.data;
        })
    },
    loadCategories() {
      this.categories = [];

      this.axios.get('/product-categories?status=1')
        .then(res => {
          this.categories = res.data.data;
        })
    },
    loadGroupCategories(preId = null) {
      this.groupCategories = [];

      if (!preId)
        this.form.product_group_category_id = '';

      if (this.form.product_category_id) {
        this.axios.get('/product-group-categories?status=1&categoryId=' + this.form.product_category_id)
          .then(res => {
            this.groupCategories = res.data.data;
          })
      }
    },
    submit() {
      this.allErrors = null;
      this.loading = true;

      let url = '/products';

      if (this.product_id) {
        url = '/products/' + this.product_id
        this.form._method = 'PATCH'
      } else {
        delete this.form._method;
      }

      this.axios.post(url, this.form)
        .then(() => {
          this.resetForm();
          this.modal.hide();
          this.$emit('added');
        })
        .catch((err) => {
          this.allErrors = err.response.data.errors;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    hide() {
      this.modal.hide();
    },
  }
}
</script>