<template>
  <div class="row">
    <div class="col-12">
      <div class="box">
        <div class="box-header small-box-header">
          <div class="row">
            <div class="col-md-9">
              <div class="input-group">
                <input type="text"
                       @keyup="searchText($event.target.value)"
                       placeholder="ค้นหาด้วยการระบุ ชื่อย่อ, ชื่อหน่วยนับ (ไทย) หรือ ชื่อหน่วยนับ (อังกฤษ) "
                       class="form-control form-control-sm">
                <span class="input-group-text"><i class="fa fa-search"></i></span>
              </div>
            </div>
            <div class="col-md-2">
              <select class="form-select form-select-sm"
                      @change="refreshData"
                      v-model="queryParams.status">
                <option value="">{{ $t('setting.all_status') }}</option>
                <option value="1">Active</option>
                <option value="0">Inactive</option>
              </select>
            </div>
            <div class="col-md-1">
              <div class="d-grid">
                <button @click="$refs.addNewUnitModal.show()"
                        class="btn btn-primary btn-sm">+ {{ $t('appointments.add_new') }}</button>
              </div>
            </div>
          </div>
        </div>
        <div class="box-body p-3">
          <table-component :columns="columns"
                           :rows="units"
                           :search="false"
                           :pagination="pagination"
                           @pageChanged="pageChanged"
                           :sortable="sortable"
                           @sorting="sorting"
                           @search="search">
            <template v-slot:action="{ row }">
              <a href="#" class="text-muted"
                 @click.prevent="edit(row)"><i class="fas fa-pen"></i></a> |
              <a href="#" class="text-muted"
                 @click.prevent="deleteData(row)"><i class="fas fa-trash"></i></a>
            </template>
          </table-component>
        </div>
      </div>
    </div>
  </div>

  <add-new-unit-modal ref="addNewUnitModal" @added="loadData" />
</template>

<script>
import AddNewUnitModal from "@/views/setting/product/modals/AddNewUnitModal";
import TableComponent from "@/components/TableComponent";
export default {
  components: {AddNewUnitModal, TableComponent},
  data() {
    return {
      units: [],
      pagination: null,
      queryParams: {
        status: '',
        page: 1,
        search: '',
        sort: 'name_th',
        sort_order: 'asc',
        per_page: 10
      },
      sortable: {
        order: 'name_th',
        sort: 'asc'
      },
      columns: [
        {
          label: "ชื่อย่อ",
          field: "initials",
          sortable: true,
          searchable: true,
        },
        {
          label: "ชื่อหน่วยนับ (ไทย)",
          field: 'name_th',
          sortable: true,
          searchable: true,
        },
        {
          label: "ชื่อหน่วยนับ (อังกฤษ)",
          field: 'name_en',
          sortable: true,
          searchable: true,
        },
        {
          label: "สถานะ",
          field: 'status',
          sortable: true,
          searchable: false,
          display(row) {
            if (row.status)
              return '<span class="badge badge-success-light">เปิดใช้งาน</span>'
            else
              return '<span class="badge badge-secondary-light">ปิดใช้งาน</span>';
          }
        },
        {
          label: "วันที่ปรับปรุง",
          field: 'updated_at',
          sortable: true,
          searchable: false,
        },
        {
          label: "ผู้ปรับปรุง",
          field: 'updatedBy.full_name',
          sortable: false,
          searchable: false,
        },
        {
          label: "Action",
          field: 'action',
          sortable: false,
          slot: true,
          class: ['text-end']
        }
      ],
    }
  },
  created() {
    this.loadData()
  },
  methods: {
    loadData() {
      this.units = [];

      this.axios.get('/product-units', {params: this.queryParams})
        .then((response) => {
          this.units = response.data.data;
          this.pagination = response.data.meta;
        })
    },
    edit(row) {
      this.axios.get('/product-units/' + row.id)
        .then(res => {
          let unit = res.data.data;
          this.$refs.addNewUnitModal.show(unit);
        });
    },
    deleteData(row) {
      this.$swal({
        title: 'Are you sure want to delete?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        confirmButtonColor: '#dd4b39',
        reverseButtons: true
      }).then((result) => {
        if (result.isConfirmed) {
          this.axios.delete('/product-units/'+row.id).then(() => {
            this.loadData();
            this.$swal.fire(
              'Deleted',
              'Product unit has been deleted!',
              'success'
            )
          });
        }
      });
    },
  }
}
</script>