<template>
  <div class="modal fade bs-example-modal-lg"
       id="add-service-category-modal"
       tabindex="-1"
       role="dialog"
       aria-labelledby="myLargeModalLabel"
       aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title" id="myLargeModalLabel">
            {{ title }}
          </h4>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div :class="['form-group form-group-sm row mb-2', {'error': errorFor('code')}]">
            <label class="col-sm-4 col-form-label">รหัสแผนกบริการ</label>
            <div class="col-sm-8">
              <div class="input-group">
                <span class="input-group-text">D</span>

                <input class="form-control form-control-sm"
                       v-model="form.code"
                       type="text">
              </div>

              <v-errors :errors="errorFor('code')"></v-errors>
            </div>
          </div>
          <div :class="['form-group form-group-sm row mb-0', {'error': errorFor('name_th')}]">
            <label class="col-sm-4 col-form-label">ชื่อแผนกบริการ (ไทย)</label>
            <div class="col-sm-8">
              <input class="form-control form-control-sm"
                     v-model="form.name_th"
                     type="text">

              <v-errors :errors="errorFor('name_th')"></v-errors>
            </div>
          </div>
          <div :class="['form-group form-group-sm row mb-0', {'error': errorFor('name_en')}]">
            <label class="col-sm-4 col-form-label">ชื่อแผนกบริการ (อังกฤษ)</label>
            <div class="col-sm-8">
              <input class="form-control form-control-sm"
                     v-model="form.name_en"
                     type="text">

              <v-errors :errors="errorFor('name_en')"></v-errors>
            </div>
          </div>
        </div>
        <div class="modal-footer text-center">
          <button type="button"
                  class="btn btn-secondary"
                  data-bs-dismiss="modal">ยกเลิก</button>

          <button class="btn btn-success"
                  @click="submit"
                  :disabled="loading">บันทึก</button>
        </div>
      </div>
      <!-- /.modal-content -->
    </div>
    <!-- /.modal-dialog -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      modal: null,
      title: 'เพิ่มแผนกบริการ',
      category_id: '',
      form: {
        name_th: '',
        name_en: '',
        code: ''
      }
    }
  },
  mounted() {
    this.modal = new window.bootstrap.Modal(document.getElementById('add-service-category-modal'));
  },
  methods: {
    resetForm() {
      this.allErrors = null;

      this.form.name_en = '';
      this.form.name_th = '';
      this.form.code = '';
    },
    show(category = null) {
      this.resetForm();

      if (category) {
        this.title = 'แก้ไขแผนกบริการ';
        this.form = category;
        this.category_id = category.id
        delete this.form.id;
      } else {
        this.category_id = '';
        this.title = 'เพิ่มแผนกบริการ';
      }

      this.modal.show();
    },
    submit() {
      this.allErrors = null;
      this.loading = true;

      let url = '/service-categories';

      if (this.category_id) {
        url = '/service-categories/' + this.category_id
        this.form._method = 'PATCH'
      } else {
        delete this.form._method;
      }

      this.axios.post(url, this.form)
        .then(() => {
          this.resetForm();
          this.modal.hide();
          this.$emit('added');
        })
        .catch((err) => {
          this.allErrors = err.response.data.errors;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    hide() {
      this.modal.hide();
    },
  }
}
</script>