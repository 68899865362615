<template>
  <div class="row">
    <div class="col-12">
      <div class="box">
        <div class="box-header small-box-header">
          <div class="row">
            <div class="col-md-9">
              <div class="input-group">
                <input type="text"
                       @keyup="searchText($event.target.value)"
                       placeholder="ค้นหาด้วยการระบ ชื่อหมวด (ไทย), ชื่อหมวด (อังกฤษ), ชื่อหมู่ (ไทย), ชื่อหมู่ (อังกฤษ)"
                       class="form-control form-control-sm">
                <span class="input-group-text"><i class="fa fa-search"></i></span>
              </div>
            </div>
            <div class="col-md-2">
              <select class="form-select form-select-sm"
                      @change="refreshData"
                      v-model="queryParams.status">
                <option value="">{{ $t('setting.all_status') }}</option>
                <option value="1">{{ $t('setting.active') }}</option>
                <option value="0">{{ $t('setting.inactive') }}</option>
              </select>
            </div>
            <div class="col-md-1">
              <div class="d-grid">
                <button @click="$refs.addNewCategoryModal.show()"
                        class="btn btn-primary btn-sm">+ Add New</button>
              </div>
            </div>
          </div>
        </div>
        <div class="box-body p-3">
          <table-component :columns="columns"
                           :rows="categories"
                           :hasSubTable="true"
                           @sorting="sorting"
                           :pagination="pagination"
                           @search="search"
                           :search="false"
                           @pageChanged="pageChanged">

            <template v-slot:subTable="{ row, index }">
              <tr :class="['bg-secondary-light', {'d-none': activeRowIndex !== index}]">
                <td :colspan="columns.length" class="p-0">
                  <table class="table m-0">
                    <thead>
                    <tr>
                      <th>ชื่อหมวด/หมู่สินค้า (ไทย)</th>
                      <th>ชื่อหมวด/หมู่สินค้า (อังกฤษ)</th>
                      <th>สถานะ</th>
                      <th>วันที่ปรับปรุง</th>
                      <th>ผู้ปรับปรุง</th>
                      <th class="text-end">{{ $t('setting.action') }}</th>
                    </tr>
                    </thead>

                    <tbody>
                    <tr v-for="(item, ii) in row.groups" :key="'item_'+ii">
                      <td>{{ item.name_th }}</td>
                      <td>{{ item.name_en }}</td>
                      <td>
                        <span class="badge badge-success-light" v-if="item.status">เปิดใช้งาน</span>
                        <span class="badge badge-secondary-light" v-else>ปิดใช้งาน</span>
                      </td>
                      <td>{{ item.updated_at }}</td>
                      <td>{{ item.updatedBy.full_name }}</td>
                      <td class="text-end">
                        <a href="#"
                           class="text-muted"
                           @click.prevent="$refs.addNewCategoryGroupModal.show(row, item)">
                          <i class="fas fa-pen"></i>
                        </a> |

                        <a href="#"
                           class="text-muted"
                           @click.prevent="deleteCategoryGroup(item)">
                          <i class="fas fa-trash"></i>
                        </a>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
            </template>

            <template v-slot:name_th="{ row, index }">
              <a href="#"
                 @click.prevent="changeSelectedRowIndex(index)">
                <span v-if="activeRowIndex === index"><i class="fas fa-minus"></i></span>
                <span v-else><i class="fas fa-plus"></i></span>
                {{ row.name_th }}
              </a>
            </template>

            <template v-slot:action="{ row }">
              <a href="#"
                 class="text-muted"
                 @click.prevent="$refs.addNewCategoryGroupModal.show(row)">
                <i class="fas fa-plus-square"></i>
              </a> |

              <a href="#"
                 class="text-muted"
                 @click.prevent="edit(row)"><i class="fas fa-pen"></i></a> |

              <a href="#" class="text-muted" @click.prevent="deleteData(row)">
                <i class="fas fa-trash"></i>
              </a>
            </template>
          </table-component>
        </div>
      </div>
    </div>
  </div>

  <add-new-category-modal ref="addNewCategoryModal" @added="loadData" />
  <add-new-category-group-modal ref="addNewCategoryGroupModal" @added="loadData" />
</template>

<script>
import TableComponent from "@/components/TableComponent";
import AddNewCategoryModal from "@/views/setting/product/modals/AddNewCategoryModal";
import AddNewCategoryGroupModal from "@/views/setting/product/modals/AddNewCategoryGroupModal";
export default {
  components: {TableComponent, AddNewCategoryModal, AddNewCategoryGroupModal},
  data() {
    return {
      categories: [],
      activeRowIndex: null,
      pagination: null,
      queryParams: {
        status: '',
        page: 1,
        search: '',
        sort: 'name_th',
        sort_order: 'asc',
        per_page: 10
      },
      sortable: {
        order: 'name_th',
        sort: 'asc'
      },
      columns: [
        {
          label: "ชื่อหมวด/หมู่สินค้า (ไทย)",
          field: "name_th",
          sortable: true,
          searchable: true,
          slot: true,
        },
        {
          label: "ชื่อหมวด/หมู่สินค้า (อังกฤษ)",
          field: "name_en",
          sortable: true,
          searchable: true,
        },
        {
          label: "สถานะ",
          field: 'status',
          sortable: true,
          searchable: false,
          display(row) {
            if (row.status)
              return '<span class="badge badge-success-light">เปิดใช้งาน</span>'
            else
              return '<span class="badge badge-secondary-light">ปิดใช้งาน</span>';
          }
        },
        {
          label: "วันที่ปรับปรุง",
          field: 'updated_at',
          sortable: true,
          searchable: false,
        },
        {
          label: "ผู้ปรับปรุง",
          field: 'updatedBy.full_name',
          sortable: false,
          searchable: false,
        },
        {
          label: "Action",
          field: "action",
          sortable: false,
          searchable: false,
          slot: true,
          class: ['text-end']
        },
      ],
    }
  },
  created() {
    this.loadData()
  },
  methods: {
    loadData() {
      this.categories = [];
      this.activeRowIndex = null;

      this.axios.get('/product-categories', {params: this.queryParams})
        .then((response) => {
          this.categories = response.data.data;
          this.pagination = response.data.meta;
        })
    },
    edit(row) {
      this.axios.get('/product-categories/' + row.id)
        .then(res => {
          let category = res.data.data;
          this.$refs.addNewCategoryModal.show(category);
        });
    },
    changeSelectedRowIndex(index) {
      if (this.activeRowIndex === index)
        this.activeRowIndex = null;
      else {
        this.activeRowIndex = index
      }
    },
    deleteCategoryGroup(row) {
      this.$swal({
        title: this.$t('patients.are_you_sure_want_to_delete'),
        text: this.$t('patients.you_wont_be_able_to_revert_this'),
        icon: 'warning',
        showCancelButton: true,
        cancelButtonText: this.$t('patients.cancel'),
        confirmButtonText: this.$t('patients.yes_delete_it'),
        confirmButtonColor: '#dd4b39',
        reverseButtons: true
      }).then((result) => {
        if (result.isConfirmed) {
          this.axios.delete('/product-group-categories/'+row.id).then(() => {
            this.loadData();
            this.$swal.fire(
              'Deleted',
              'Category group has been deleted!',
              'success'
            )
          });
        }
      });
    },
    deleteData(row) {
      this.$swal({
        title: this.$t('patients.are_you_sure_want_to_delete'),
        text: this.$t('patients.you_wont_be_able_to_revert_this'),
        icon: 'warning',
        showCancelButton: true,
        cancelButtonText: this.$t('patients.cancel'),
        confirmButtonText: this.$t('patients.yes_delete_it'),
        confirmButtonColor: '#dd4b39',
        reverseButtons: true
      }).then((result) => {
        if (result.isConfirmed) {
          this.axios.delete('/product-categories/'+row.id).then(() => {
            this.loadData();
            this.$swal.fire(
              this.$t('patients.deleted'),
              this.$t('patients.patient_has_been_deleted'),
              'success'
            )
          });
        }
      });
    },
  }
}
</script>