<template>
  <div class="content-header">
    <div class="d-flex align-items-center">
      <div class="me-auto">
        <h3 class="page-title">{{ $t('setting.settings') }}</h3>
        <div class="d-inline-block align-items-center">
          <nav>
            <ol class="breadcrumb">
              <li class="breadcrumb-item"><a href="#"><i class="mdi mdi-home-outline"></i></a></li>
              <li class="breadcrumb-item active" aria-current="page"> {{ $t('setting.product') }} </li>
            </ol>
          </nav>
        </div>
      </div>
    </div>
  </div>

  <div class="row mb-3">
    <div class="col-md-12">
      <router-link :class="'btn btn-sm me-3 ' +  ($route.name === 'settings_product_list' ?  'btn-primary' : 'btn-secondary')"
                   :to="{name: 'settings_product_list'}">รายการสินค้า</router-link>
      <router-link :class="'btn btn-sm me-3 ' +  ($route.name === 'settings_product_category' ?  'btn-primary' : 'btn-secondary')"
                   :to="{name: 'settings_product_category'}">หมวดสินค้า/หมู่สินค้า</router-link>
      <router-link :class="'btn btn-sm ' +  ($route.name === 'settings_product_unit' ?  'btn-primary' : 'btn-secondary')"
                   :to="{name: 'settings_product_unit'}">หน่วยนับ</router-link>
    </div>
  </div>

  <router-view />
</template>