<template>
  <div class="modal fade bs-example-modal-lg"
       id="details-view-modal"
       tabindex="-1"
       role="dialog"
       aria-labelledby="myLargeModalLabel"
       aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title" id="myLargeModalLabel">
            ข้อมูลสินค้า
          </h4>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div class="form-group form-group-sm row mb-0">
            <label class="col-sm-4 col-form-label">รหัสสินค้า</label>
            <div class="col-sm-8">
              <input class="form-control form-control-sm"
                     type="text"
                     :value="product ? product.code : ''" disabled>
            </div>
          </div>

          <div class="form-group form-group-sm row mb-0">
            <label class="col-sm-4 col-form-label">ชื่อสินค้า (ขาย)</label>
            <div class="col-sm-8">
              <input class="form-control form-control-sm"
                     type="text"
                     :value="product ? product.name_sale : ''" disabled>
            </div>
          </div>

          <div class="box">
            <div class="box-body p-2">
              <div class="table-responsive">
                <table class="table table-small">
                  <thead>
                  <tr>
                    <th>No.</th>
                    <th>Lot No.</th>
                    <th>จำนวนสินค้าที่รับ</th>
                    <th>จำนวนสินค้าที่ใช้</th>
                    <th class="text-end">จำนวนคงเหลือ</th>
                  </tr>
                  </thead>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- /.modal-content -->
    </div>
    <!-- /.modal-dialog -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      modal: null,
      product: null
    }
  },
  mounted() {
    this.modal = new window.bootstrap.Modal(document.getElementById('details-view-modal'));
  },
  methods: {
    show(product) {
      this.product = {...product};

      this.modal.show();
    },
    hide() {
      this.modal.hide();
    },
  }
}
</script>