<template>
  <div class="modal fade bs-example-modal-lg"
       id="add-service-category-group-modal"
       tabindex="-1"
       role="dialog"
       aria-labelledby="myLargeModalLabel"
       aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title" id="myLargeModalLabel">
            <i class="fas fa-plus-square"></i> {{ title }}
          </h4>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div class="form-group form-group-sm row mb-0">
            <label class="col-sm-4 col-form-label">แผนกบริการ</label>
            <div class="col-sm-8">
              <input type="text"
                     :value="category ? category.name_th : ''"
                     disabled
                     class="form-control form-control-sm">
            </div>
          </div>
          <div :class="['form-group form-group-sm row mb-2', {'error': errorFor('code')}]">
            <label class="col-sm-4 col-form-label">รหัสกลุ่มบริการ</label>
            <div class="col-sm-8">
              <div class="input-group">
                <span class="input-group-text">G</span>

                <input class="form-control form-control-sm"
                       v-model="form.code"
                       type="text">
              </div>

              <v-errors :errors="errorFor('code')"></v-errors>
            </div>
          </div>
          <div :class="['form-group form-group-sm row mb-0', {'error': errorFor('name_th')}]">
            <label class="col-sm-4 col-form-label">ชื่อหมวด (ไทย)</label>
            <div class="col-sm-8">
              <input class="form-control form-control-sm"
                     v-model="form.name_th"
                     type="text">

              <v-errors :errors="errorFor('name_th')"></v-errors>
            </div>
          </div>
          <div :class="['form-group form-group-sm row mb-0', {'error': errorFor('name_en')}]">
            <label class="col-sm-4 col-form-label">ชื่อหมวด (อังกฤษ)</label>
            <div class="col-sm-8">
              <input class="form-control form-control-sm"
                     v-model="form.name_en"
                     type="text">

              <v-errors :errors="errorFor('name_en')"></v-errors>
            </div>
          </div>
        </div>
        <div class="modal-footer text-center">
          <button type="button"
                  class="btn btn-secondary"
                  data-bs-dismiss="modal">ยกเลิก</button>

          <button class="btn btn-success"
                  @click="submit"
                  :disabled="loading">บันทึก</button>
        </div>
      </div>
      <!-- /.modal-content -->
    </div>
    <!-- /.modal-dialog -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      modal: null,
      title: 'เพิ่มกลุ่มบริการ',
      category: null,
      group_id: '',
      form: {
        name_th: '',
        name_en: '',
        code: '',
        service_category_id: ''
      }
    }
  },
  mounted() {
    this.modal = new window.bootstrap.Modal(document.getElementById('add-service-category-group-modal'));
  },
  methods: {
    resetForm() {
      this.allErrors = null;

      this.form.name_en = '';
      this.form.name_th = '';
      this.form.code = '';
    },
    show(category, group = null) {
      this.resetForm();
      this.category = {...category};
      this.form.service_category_id = category.id;

      if (group) {
        this.title = 'แก้ไขกลุ่มบริการ';
        this.form = {...group};
        this.group_id = group.id
        delete this.form.id;
      } else {
        this.group_id = '';
        this.title = 'เพิ่มกลุ่มบริการ';
      }

      this.modal.show();
    },
    submit() {
      this.allErrors = null;
      this.loading = true;

      let url = '/service-group-categories';

      if (this.group_id) {
        url = '/service-group-categories/' + this.group_id
        this.form._method = 'PATCH'
      } else {
        delete this.form._method;
      }

      this.axios.post(url, this.form)
        .then(() => {
          this.resetForm();
          this.modal.hide();
          this.$emit('added');
        })
        .catch((err) => {
          this.allErrors = err.response.data.errors;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    hide() {
      this.modal.hide();
    },
  }
}
</script>