<template>
  <div class="modal fade bs-example-modal-lg"
       id="add-new-service-station-modal"
       tabindex="-1"
       role="dialog"
       aria-labelledby="myLargeModalLabel"
       aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title" id="myLargeModalLabel">
            {{ title }}
          </h4>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div :class="['form-group form-group-sm row mb-0', {'error': errorFor('name_th')}]">
            <label class="col-sm-4 col-form-label">ชื่อจุดรับบริการ (ไทย)</label>
            <div class="col-sm-8">
              <input class="form-control form-control-sm"
                     v-model="form.name_th"
                     type="text">

              <v-errors :errors="errorFor('name_th')"></v-errors>
            </div>
          </div>
          <div :class="['form-group form-group-sm row mb-0', {'error': errorFor('name_en')}]">
            <label class="col-sm-4 col-form-label">ชื่อจุดรับบริการ (อังกฤษ)</label>
            <div class="col-sm-8">
              <input class="form-control form-control-sm"
                     v-model="form.name_en"
                     type="text">

              <v-errors :errors="errorFor('name_en')"></v-errors>
            </div>
          </div>
          <div class="form-group form-group-sm row mb-0">
            <label class="col-sm-4 col-form-label">แผนกบริการ</label>
            <div class="col-sm-8">
              <div class="form-group">
                <div class="checkbox"
                     v-for="department in departments"
                     :key="'department_'+department.id">
                  <input type="checkbox"
                         v-model="form.departments"
                         name="department[]"
                         :value="department.id"
                         :id="'department_checkbox_'+department.id">
                  <label :for="'department_checkbox_'+department.id">{{ department.name }}</label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer text-center">
          <button type="button"
                  class="btn btn-secondary"
                  data-bs-dismiss="modal">ยกเลิก</button>

          <button class="btn btn-success"
                  @click="submit"
                  :disabled="loading">บันทึก</button>
        </div>
      </div>
      <!-- /.modal-content -->
    </div>
    <!-- /.modal-dialog -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      modal: null,
      title: 'เพิ่มจุดรับบริการ',
      station_id: '',
      departments: [],
      form: {
        name_th: '',
        name_en: '',
        departments: []
      }
    }
  },
  created() {
    this.loadDepartments();
  },
  mounted() {
    this.modal = new window.bootstrap.Modal(document.getElementById('add-new-service-station-modal'));
  },
  methods: {
    loadDepartments() {
      this.departments = [];

      this.axios.get('/departments')
        .then(res => {
          this.departments = res.data.data;
        })
    },
    resetForm() {
      this.allErrors = null;

      this.form.name_en = '';
      this.form.name_th = '';
      this.form.departments = [];
    },
    show(station = null) {
      this.resetForm();

      if (station) {
        this.title = 'แก้ไขจุดบริการ';
        this.form = station;
        this.station_id = station.id
        this.form.departments = station.departments.map(d => d.id);

        delete this.form.id;
      } else {
        this.station_id = '';
        this.title = 'เพิ่มจุดรับบริการ';
      }

      this.modal.show();
    },
    submit() {
      this.allErrors = null;
      this.loading = true;

      let url = '/service-stations';

      if (this.station_id) {
        url = '/service-stations/' + this.station_id
        this.form._method = 'PATCH'
      } else {
        delete this.form._method;
      }

      this.axios.post(url, this.form)
        .then(() => {
          this.resetForm();
          this.modal.hide();
          this.$emit('added');
        })
        .catch((err) => {
          this.allErrors = err.response.data.errors;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    hide() {
      this.modal.hide();
    },
  }
}
</script>