<template>
  <div class="modal fade bs-example-modal-lg"
       id="add-product-modal"
       tabindex="-1"
       role="dialog"
       aria-labelledby="myLargeModalLabel"
       aria-hidden="true">
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title" id="myLargeModalLabel">
            เพิ่มผลิตภัณฑ์
          </h4>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div class="form-group row mb-0">
            <label class="col-sm-1 col-form-label">คำค้นหา</label>
            <div class="col-sm-10">
              <div class="input-group">
                <input type="text"
                       v-model="queryParams.search"
                       class="form-control form-control-sm">
                <span class="input-group-text"><i class="fa fa-search"></i></span>
              </div>
            </div>
            <div class="col-sm-1">
              <div class="d-grid">
                <button class="btn btn-sm btn-primary" @click="loadProducts">ค้นหา</button>
              </div>
            </div>
          </div>
          <div class="form-group row mb-0">
            <label class="col-sm-1 col-form-label">หมวดหมู่</label>
            <div class="col-sm-5">
              <select class="form-select form-select-sm"
                      @change="changeCategory"
                      v-model="queryParams.product_category_id">
                <option value="">หมวด: ทุกหมวด</option>
                <option :value="category.id"
                        v-for="category in categories"
                        :key="'category_'+category.id">{{ category.name_th }}</option>
              </select>
            </div>
            <label class="col-sm-1 col-form-label">หมู่</label>
            <div class="col-sm-4">
              <select class="form-select form-select-sm"
                      v-model="queryParams.product_group_category_id">
                <option value="">หมู่: ทุกหมู่</option>
                <option :value="group.id"
                        v-for="group in groupCategories"
                        :key="'group_'+group.id">{{ group.name_th }}</option>
              </select>
            </div>
            <div class="col-sm-1">
              <div class="d-grid">
                <button class="btn btn-sm btn-info-light"
                        @click="clear">เคลียร์</button>
              </div>
            </div>
          </div>

          <div class="row mt-4">
            <div class="col-md-12">
              <div class="box">
                <div class="box-body p-3">
                  <div class="table-responsive">
                    <table class="table table-small">
                      <thead>
                      <tr>
                        <th class="text-center">เลือก</th>
                        <th>รหัสสินค้า</th>
                        <th>ชื่อสินค้า (ขาย)</th>
                        <th>หมวด</th>
                        <th>หมู่</th>
                        <th>หน่วยนับ</th>
                        <th class="text-end">คงเหลือ</th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr v-for="product in products" :key="'product_'+product.id">
                        <td class="text-center">
                          <div class="checkbox">
                            <input :value="product.id"
                                   type="checkbox"
                                   name="selectedProducts"
                                   v-model="selectedProducts"
                                   :id="'product_'+product.id">
                            <label :for="'product_'+product.id"></label>
                          </div>
                        </td>
                        <td>{{ product.code }}</td>
                        <td>{{ product.name_sale }}</td>
                        <td>{{ product.productCategory.name_th }}</td>
                        <td>{{ product.productGroupCategory.name_th }}</td>
                        <td>{{ product.productUnit.name_th }}</td>
                        <td class="text-end">{{ product.balance }}</td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer text-end">
          <button type="button"
                  class="btn btn-secondary"
                  data-bs-dismiss="modal">ปิดหน้าจอ</button>

          <button class="btn btn-primary"
                  @click="addProduct">เพิ่มรายการที่เลือก</button>
        </div>
      </div>
      <!-- /.modal-content -->
    </div>
    <!-- /.modal-dialog -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      selectedProducts: [],
      products: [],
      categories: [],
      groupCategories: [],
      queryParams: {
        product_category_id: '',
        product_group_category_id: '',
        search: '',
        search_columns: 'code,name_sale,productCategory.name_th,productGroupCategory.name_th,balance,productUnit.name_th'
      }
    }
  },
  created() {
    this.loadProducts();
    this.loadCategories();
  },
  mounted() {
    this.modal = new window.bootstrap.Modal(document.getElementById('add-product-modal'));
  },
  methods: {
    addProduct() {
      this.$emit('addProducts', this.selectedProducts)
      this.hide();
    },
    clear() {
      this.queryParams.search = '';
      this.queryParams.product_category_id = '';
      this.queryParams.product_group_category_id = '';

      this.loadProducts();
    },
    show(selectedProducts) {
      this.clear();
      this.selectedProducts = [...selectedProducts];
      this.modal.show();
    },
    hide() {
      this.modal.hide();
    },
    loadProducts() {
      this.products = [];

      this.axios.get('/products', {params: this.queryParams})
        .then(res => {
          this.products = res.data.data;
        });
    },
    loadCategories() {
      this.categories = [];

      this.axios.get('/product-categories?status=1')
        .then(res => {
          this.categories = res.data.data;
        })
    },
    changeCategory() {
      this.loadGroupCategories();
    },
    loadGroupCategories() {
      this.groupCategories = [];
      this.queryParams.product_group_category_id = '';

      if (this.queryParams.product_category_id) {
        this.axios.get('/product-group-categories?status=1&categoryId=' + this.queryParams.product_category_id)
          .then(res => {
            this.groupCategories = res.data.data;
          })
      }
    },
  }
}
</script>