<template>
  <button @click.prevent="logoutGoogle"
          v-if="settings && settings.google_name"
          class="btn btn-dark">Logout {{ settings.google_name }}</button>

  <button @click.prevent="googleSignIn"
          v-else
          class="btn btn-primary">Google Signin</button>
</template>

<script>
export default {
  data() {
    return {
      settings: null,
    }
  },
  created() {
    this.loadSetting();

    if ("code" in this.$route.query) {
      this.loading = true;

      this.axios.post('/settings/system/google-auth', {
          code: this.$route.query.code
        })
        .then(res => {
          this.settings = res.data.data;
        })
    }
  },
  methods: {
    logoutGoogle() {
      this.axios.post('/settings/system/logout-google')
        .then(res => {
          this.settings = res.data.data;
        })
    },
    loadSetting() {
      this.settings = null;

      this.axios.get('/settings/system')
        .then(res => {
          this.settings = res.data.data;
        })
    },
    googleSignIn() {
      // Google's OAuth 2.0 endpoint for requesting an access token
      let oauth2Endpoint = 'https://accounts.google.com/o/oauth2/v2/auth';

      // Create <form> element to submit parameters to OAuth 2.0 endpoint.
      let form = document.createElement('form');
      form.setAttribute('method', 'GET'); // Send as a GET request.
      form.setAttribute('action', oauth2Endpoint);

      // Parameters to pass to OAuth 2.0 endpoint.
      let params = {
        'client_id': process.env.VUE_APP_GOOGLE_CLIENT_ID,
        'redirect_uri': process.env.VUE_APP_URL + '/settings/system',
        'scope': 'https://www.googleapis.com/auth/calendar https://www.googleapis.com/auth/userinfo.profile email',
        'access_type': 'offline',
        'include_granted_scopes': 'true',
        'response_type': 'code',
        'state': 'google'
      };

      // Add form parameters as hidden input values.
      for (let p in params) {
        let input = document.createElement('input');
        input.setAttribute('type', 'hidden');
        input.setAttribute('name', p);
        input.setAttribute('value', params[p]);
        form.appendChild(input);
      }

      // Add form to page and submit it to open the OAuth 2.0 endpoint.
      document.body.appendChild(form);
      form.submit();
    },
  }
}
</script>