<template>
  <div class="row">
    <div class="col-12">
      <div class="box">
        <div class="box-header with-border small-box-header">
          <h4 class="box-title">ข้อมูลบริการ</h4>
        </div>
        <!-- /.box-header -->
        <div class="box-body p-3">
          <div class="row">
            <div class="col-md-6">
              <div class="form-group form-group-sm row mb-0">
                <label class="col-sm-3 col-form-label">ชื่อบริการ (อังกฤษ) <span class="text-danger">*</span></label>
                <div class="col-sm-9">
                  <div class="c-inputs-stacked">
                    <input name="user_type_status"
                           type="radio"
                           v-model="form.status"
                           id="user_type_active" value="1">
                    <label for="user_type_active" class="me-30">เปิด</label>
                    <input name="user_type_status"
                           type="radio"
                           v-model="form.status"
                           id="user_type_inactive" value="0">
                    <label for="user_type_inactive" class="me-30">ปิด</label>
                  </div>
                </div>
              </div>
              <div :class="['form-group form-group-sm row mb-0', {'error': errorFor('code')}]">
                <label class="col-sm-3 col-form-label">รหัสบริการ <span class="text-danger">*</span></label>
                <div class="col-sm-9">
                  <input class="form-control form-control-sm"
                         v-model="form.code"
                         type="text">

                  <v-errors :errors="errorFor('code')"></v-errors>
                </div>
              </div>
              <div :class="['form-group form-group-sm row mb-0', {'error': errorFor('name_th')}]">
                <label class="col-sm-3 col-form-label">ชื่อบริการ (ไทย) <span class="text-danger">*</span></label>
                <div class="col-sm-9">
                  <input class="form-control form-control-sm"
                         v-model="form.name_th"
                         type="text">

                  <v-errors :errors="errorFor('name_th')"></v-errors>
                </div>
              </div>
              <div :class="['form-group form-group-sm row mb-0', {'error': errorFor('name_en')}]">
                <label class="col-sm-3 col-form-label">ชื่อบริการ (อังกฤษ) <span class="text-danger">*</span></label>
                <div class="col-sm-9">
                  <input class="form-control form-control-sm"
                         v-model="form.name_en"
                         type="text">

                  <v-errors :errors="errorFor('name_en')"></v-errors>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div :class="['form-group form-group-sm row mb-0', {'error': errorFor('service_category_id')}]">
                <label class="col-sm-3 col-form-label">แผนกบริการ <span class="text-danger">*</span></label>
                <div class="col-sm-9">
                  <select class="form-select form-select-sm"
                          @change="loadServiceGroupCategories"
                          v-model="form.service_category_id">
                    <option value="">เลือก</option>
                    <option :value="category.id"
                            v-for="category in serviceCategories"
                            :key="'category_'+category.id">{{ category.name_th }}</option>
                  </select>

                  <v-errors :errors="errorFor('service_category_id')"></v-errors>
                </div>
              </div>
              <div :class="['form-group form-group-sm row mb-0', {'error': errorFor('service_group_category_id')}]">
                <label class="col-sm-3 col-form-label">กลุ่มบริการ <span class="text-danger">*</span></label>
                <div class="col-sm-9">
                  <select class="form-select form-select-sm"
                          v-model="form.service_group_category_id">
                    <option value="">เลือก</option>
                    <option :value="category.id"
                            v-for="category in serviceGroupCategories"
                            :key="'category_group_'+category.id">{{ category.name_th }}</option>
                  </select>

                  <v-errors :errors="errorFor('service_group_category_id')"></v-errors>
                </div>
              </div>
              <div :class="['form-group form-group-sm row mb-0', {'error': errorFor('price')}]">
                <label class="col-sm-3 col-form-label">ราคา (บาท) <span class="text-danger">*</span></label>
                <div class="col-sm-9">
                  <input class="form-control form-control-sm"
                         v-model="form.price"
                         type="text">

                  <v-errors :errors="errorFor('price')"></v-errors>
                </div>
              </div>
              <div :class="['form-group form-group-sm row mb-0', {'error': errorFor('time')}]">
                <label class="col-sm-3 col-form-label">เวลาที่ใช้ (นาที) <span class="text-danger">*</span></label>
                <div class="col-sm-9">
                  <input class="form-control form-control-sm"
                         v-model="form.time"
                         type="text">

                  <v-errors :errors="errorFor('time')"></v-errors>
                </div>
              </div>
              <div :class="['form-group form-group-sm row mb-0', {'error': errorFor('service_unit_id')}]">
                <label class="col-sm-3 col-form-label">หน่วยนับ <span class="text-danger">*</span></label>
                <div class="col-sm-9">
                  <select class="form-select form-select-sm"
                          v-model="form.service_unit_id">
                    <option value="">เลือก</option>
                    <option :value="unit.id"
                            v-for="unit in serviceUnits"
                            :key="'unit_'+unit.id">{{ unit.name_th }}</option>
                  </select>

                  <v-errors :errors="errorFor('service_unit_id')"></v-errors>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <div class="box">
        <div class="box-header with-border small-box-header">
          <div class="row">
            <div class="col-md-6">
              <h4 class="box-title">ผลิตภัณฑ์ขณะใช้บริการ / ครั้ง</h4>
            </div>
            <div class="col-md-6 text-end">
              <button class="btn btn-sm btn-primary-light"
                      @click="$refs.addProductModal.show(form.products)">+ Add New</button>
            </div>
          </div>

        </div>
        <!-- /.box-header -->
        <div class="box-body p-3">
          <div class="table-responsive">
            <table class="table table-small">
              <thead>
              <tr>
                <th>ลำดับ</th>
                <th>ชื่อสินค้า</th>
                <th>หมวด</th>
                <th>หมู่</th>
                <th>หน่วยนับ</th>
                <th>ราคา</th>
                <th>จำนวน</th>
                <th>ราคารวม</th>
                <th class="text-end">ลบ</th>
              </tr>
              </thead>

              <tbody>
              <template v-for="product in products"
                        :key="'product_'+product.id">
                <tr v-if="form.products.includes(product.id)">
                  <td>{{ product.code }}</td>
                  <td>{{ product.name_sale }}</td>
                  <td>{{ product.productCategory.name_th }}</td>
                  <td>{{ product.productGroupCategory.name_th }}</td>
                  <td>{{ product.productUnit.name_th }}</td>
                  <td>{{ product.selling_price }}</td>
                  <td>1</td>
                  <td>23</td>
                  <td class="text-end">
                    <a href="#"
                       @click.prevent="removeProduct(product)"
                       class="text-muted">
                      <i class="fas fa-trash"></i>
                    </a>
                  </td>
                </tr>
              </template>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>

  <hr>

  <div class="row">
    <div class="col-md-12 text-center">
      <router-link class="btn btn-secondary me-2"
                   :to="{name: 'settings_service_list'}">{{ $route.name === 'settings_service_edit' ? 'ยกเลิกการแก้ไข' : 'ยกเลิกการสร้าง' }}</router-link>

      <button :disabled="loading"
              @click="submit"
              class="btn btn-primary">บันทึกข้อมูล</button>
    </div>
  </div>

  <add-product-modal ref="addProductModal" @addProducts="addProducts" />
</template>

<script>
import AddProductModal from "@/views/setting/service/modals/AddProductModal";
export default {
  components: {AddProductModal},
  data() {
    return {
      products: [],
      serviceCategories: [],
      serviceGroupCategories: [],
      serviceUnits: [],
      form: {
        service_category_id: '',
        service_group_category_id: '',
        service_unit_id: '',
        status: '1',
        code: '',
        name_th: '',
        name_en: '',
        price: '',
        time: '',
        products: []
      }
    }
  },
  async created() {
    if (this.$route.name === 'settings_service_edit') {
      await this.axios.get('/services/' + this.$route.params.id)
        .then(res => {
          let service = res.data.data;
          this.form = service;
          this.form.service_category_id = service.serviceCategory.id;
          this.form.service_group_category_id = service.serviceGroupCategory.id;
          this.form.service_unit_id = service.serviceUnit.id;
          this.form.products = service.products.map(p => p.id);

          delete this.form.id;
        })
    }

    this.loadProducts();
    this.loadServiceCategories();
    this.loadServiceGroupCategories(this.form.service_group_category_id);
    this.loadServiceUnits();
  },
  methods: {
    addProducts(products) {
      this.form.products = [...this.form.products, ...products];
      this.form.products = this.form.products.filter((v,i) => !this.form.products.includes(v,i+1))
    },
    removeProduct(product) {
      this.form.products = this.form.products.filter(p => p !== product.id);
    },
    loadProducts() {
      this.products = [];

      this.axios.get('/products')
        .then(res => {
          this.products = res.data.data;
        });
    },
    loadServiceUnits() {
      this.serviceUnits = [];

      this.axios.get('/service-units?status=1')
        .then(res => {
          this.serviceUnits = res.data.data;
        })
    },
    loadServiceCategories() {
      this.serviceCategories = [];

      this.axios.get('/service-categories')
        .then(res => {
          this.serviceCategories = res.data.data;
        })
    },
    loadServiceGroupCategories(prevId = null) {
      this.serviceGroupCategories = [];

      if (!prevId)
        this.form.service_group_category_id = '';

      if (this.form.service_category_id) {
        this.axios.get('/service-group-categories?service_category_id=' + this.form.service_category_id)
          .then(res => {
            this.serviceGroupCategories = res.data.data;
          })
      }
    },
    submit() {
      this.allErrors = null;
      this.loading = true;

      let url = '/services';

      if (this.$route.name === 'settings_service_edit') {
        url = '/services/' + this.$route.params.id;
        this.form._method = 'PATCH'
      } else {
        delete this.form._method;
      }

      this.axios.post(url, this.form)
        .then(() => {
          this.$router.push({name: 'settings_service_list'})
        })
        .catch((err) => {
          this.allErrors = err.response.data.errors;
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
}
</script>