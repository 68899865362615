<template>
  <div class="modal fade bs-example-modal-lg"
       id="add-role-modal"
       tabindex="-1"
       role="dialog"
       aria-labelledby="myLargeModalLabel"
       aria-hidden="true">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title" id="myLargeModalLabel">
            {{ title }} <br>
            <small class="text-muted">กรุณากรอกข้อมูลในช่องที่มีเครื่องหมายดอกจัน (*) ให้ถูกต้องและครบถ้วน</small>
          </h4>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div class="form-group form-group-sm row mb-0">
            <label class="col-sm-3 col-form-label">สถานะใช้งาน</label>
            <div class="col-sm-9">
              <div class="c-inputs-stacked">
                <input name="user_type_status"
                       type="radio"
                       v-model="form.status"
                       id="user_type_active" value="1">
                <label for="user_type_active" class="me-30">เปิด</label>
                <input name="user_type_status"
                       type="radio"
                       v-model="form.status"
                       id="user_type_inactive" value="0">
                <label for="user_type_inactive" class="me-30">ปิด</label>
              </div>
            </div>
          </div>
          <div :class="['form-group form-group-sm row mb-0', {'error': errorFor('name_th')}]">
            <label class="col-sm-3 col-form-label">ชื่อประเภท (TH) <span class="text-danger">*</span></label>
            <div class="col-sm-9">
              <input class="form-control form-control-sm"
                     v-model="form.name_th"
                     type="text">

              <v-errors :errors="errorFor('name_th')"></v-errors>
            </div>
          </div>
          <div :class="['form-group form-group-sm row mb-0', {'error': errorFor('name_en')}]">
            <label class="col-sm-3 col-form-label">ชื่อประเภท (EN) <span class="text-danger">*</span></label>
            <div class="col-sm-9">
              <input class="form-control form-control-sm"
                     v-model="form.name_en"
                     type="text">

              <v-errors :errors="errorFor('name_en')"></v-errors>
            </div>
          </div>
          <div :class="['form-group form-group-sm row mb-0', {'error': errorFor('permissions')}]">
            <label class="col-sm-3 col-form-label">กำหนดสิทธิ์การใช้เมนู <span class="text-danger">*</span></label>
            <div class="col-sm-9">
              <select class="form-control form-control-sm select2"
                      id="permissions-select"
                      multiple="multiple"
                      style="width: 100%;">
                <option :value="permission.id"
                        v-for="permission in permissions"
                        :key="'permission_'+permission.id">{{ permission.name }}</option>
              </select>

              <v-errors :errors="errorFor('permissions')"></v-errors>
            </div>
          </div>
        </div>
        <div class="modal-footer text-center">
          <button type="button"
                  class="btn btn-secondary"
                  data-bs-dismiss="modal">
            {{ role_id ? 'ยกเลิกการแก้ไข' : 'ยกเลิกการสร้าง' }}
          </button>

          <button class="btn btn-success"
                  @click="submit"
                  :disabled="loading">บันทึก</button>
        </div>
      </div>
      <!-- /.modal-content -->
    </div>
    <!-- /.modal-dialog -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      modal: null,
      title: 'เพิ่มประเภทผู้ใช้งาน',
      permissions: [],
      role_id: '',
      form: {
        name_th: '',
        name_en: '',
        permissions: [],
        status: '1'
      }
    }
  },
  mounted() {
    this.modal = new window.bootstrap.Modal(document.getElementById('add-role-modal'));

    this.getPermissions();
  },
  methods: {
    resetForm() {
      this.form.name_en = '';
      this.form.name_th = '';
      this.form.status = '1';
      this.form.permissions = [];
      window.$('#permissions-select').val(this.form.permissions).trigger('change');
    },
    show(role = null) {
      this.resetForm();

      if (role) {
        this.title = 'แก้ไขประเภทผู้ใช้';
        this.form = role;
        this.role_id = role.id
        delete this.form.id;

        let selectedPermissions = this.permissions.filter(p => role.permissions.includes(p.name));
        this.form.permissions = selectedPermissions.map(p => p.id);

        window.$('#permissions-select').val(this.form.permissions).trigger('change');
      } else {
        this.role_id = '';
        this.title = 'เพิ่มประเภทผู้ใช้งาน';
      }

      this.modal.show();

    },
    hide() {
      this.modal.hide();
    },
    submit() {
      this.allErrors = null;
      this.loading = true;

      let url = '/roles';

      if (this.role_id) {
        url = '/roles/' + this.role_id
        this.form._method = 'PATCH'
      } else {
        delete this.form._method;
      }

      this.axios.post(url, this.form)
        .then(() => {
          this.resetForm();
          this.modal.hide();
          this.$emit('added');
        })
        .catch((err) => {
          this.allErrors = err.response.data.errors;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getPermissions() {
      this.permissions = [];

      this.axios.get('/permissions')
        .then(res => {
          this.permissions = res.data.data;

          setTimeout(() => {
            this.initSelect2();
          }, 100);
        })
    },
    initSelect2() {
      let self = this;

      window.$('#permissions-select').select2({
        placeholder: 'เลือกสิทธิ์การใช้เมนู',
        dropdownParent: window.$('#add-role-modal')
      });

      window.$('#permissions-select').on('change', function () {
        self.form.permissions = window.$("#permissions-select").select2("val");
      });
    }
  }
}
</script>